import React from 'react'
import Img from 'gatsby-image'
import Reveal from 'react-reveal/Reveal'
import Scroll from 'react-scroll'
import styles from '../styles/components/hero.module.scss'
import classNames from 'classnames/bind'
import ScrollElement from './scrollElement'
import '../styles/animate.css'

let cx = classNames.bind(styles)

let Element = Scroll.Element

const Hero = props => {
  //console.log('Hero header size is ' + props.headerSize)
  let headerClass = cx('header', props.headerSize)
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: props.backgroundColor }}
    >
      {props.pageTitle === 'Case Studies' && (
        <Element name="ScrollDown">
          <ScrollElement next="Scroll Down" />
        </Element>
      )}
      {props.hasBackgroundImage && (
        <Img
          loading="eager"
          fluid={props.imageFluid.fluid}
          className={styles.backgroundImage}
          alt=""
        />
        //<img className={styles.backgroundImage} src={props.imageURL} alt="" />
      )}
      {props.header && (
        <div className={styles.textBlock}>
          <Reveal effect="fadeInUp">
            <div className={styles.pageTitle}>{props.pageTitle}</div>
          </Reveal>
          <Reveal effect="fadeInUp" duration={1500}>
            <div className={headerClass} id={styles.header}>
              {props.header}{' '}
              <span className={styles.highlightedText}>
                {props.headerHighlightedText}
              </span>
            </div>
          </Reveal>
          <Reveal effect="fadeInUp" duration={2000}>
            <div className={styles.subheader}>{props.subheader}</div>
          </Reveal>
        </div>
      )}
    </div>
  )
}

export default Hero
