import React from 'react'
import Scroll from 'react-scroll'
import styles from '../styles/pages/case-studies-page-styles.module.scss'
import Layout from '../components/layout'
import SEO from '../components/SEO'

import Hero from '../components/hero'
import CaseStudyItem from '../components/caseStudyItem'
import LogoGrid from '../components/logoGrid'
import Footer from '../components/footer'

import { Link } from 'gatsby'

import Image from '../components/image'

import { graphql } from 'gatsby'

let Element = Scroll.Element

const CaseStudiesPage = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isMobile: null }
  }
  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ isMobile: true })
    }
    window.onresize = () => {
      this.setState(prevState => ({
        isMobile: window.innerWidth <= 768,
      }))
    }
  }
  render() {
    let indexCounter = 0
    let caseStudies = this.props.data.contentfulPage.caseStudies
    // const caseStudiesArray = this.props.data.allContentfulCaseStudyList.edges[0].node.caseStudies
    const caseStudiesArray = this.props.data.contentfulPage.caseStudies.map(
      (caseStudy, i) => {
        //console.log('in case studies: slug is ' + caseStudy.slug)
        // alternate image alignment every other
        let isLeftAligned = !(indexCounter % 2)
        indexCounter++
        // parse keywords and add spacing

        let keywords =
          caseStudy.keywords !== null
            ? caseStudy.keywords.replace(/,/g, '\xa0\xa0\xa0\xa0')
            : ''
        let description =
          caseStudy.projectDescription !== null
            ? caseStudy.projectDescription.projectDescription
            : ''

        let whiteSketch
        if (caseStudy.whiteSketch === null) {
          whiteSketch = caseStudy.sketch
        } else {
          whiteSketch = caseStudy.whiteSketch
        }

        let prevProjectTitle =
          i !== 0 ? this.props.data.contentfulPage.caseStudies[i - 1].title : ''

        let nextProjectTitle =
          i !== this.props.data.contentfulPage.caseStudies.length - 1
            ? this.props.data.contentfulPage.caseStudies[i + 1].title
            : ''
        // if i === 0 i.e. first case study, set prev color to current color
        let prevProjectColor =
          i !== 0
            ? this.props.data.contentfulPage.caseStudies[i - 1].backgroundColor
            : caseStudy.backgroundColor
        // if i === length - 1 i.e. last case study, set next color to current color
        let nextProjectColor =
          i !== this.props.data.contentfulPage.caseStudies.length - 1
            ? this.props.data.contentfulPage.caseStudies[i + 1].backgroundColor
            : caseStudy.backgroundColor

        return (
          <Element key={caseStudy.slug} name={caseStudy.title}>
            <CaseStudyItem
              homePage={false}
              slug={caseStudy.slug}
              title={caseStudy.title}
              description={description}
              keywords={keywords}
              isOnHomePage={caseStudy.isOnHomePage}
              thumbnailImage={caseStudy.thumbnailImage}
              sketchImage={caseStudy.sketch}
              whiteSketchImage={caseStudy.whiteSketch}
              isLeftAligned={isLeftAligned}
              backgroundColor={caseStudy.backgroundColor}
              prev={prevProjectTitle}
              next={nextProjectTitle}
              prevColor={prevProjectColor}
              nextColor={nextProjectColor}
            />
          </Element>
        )
      }
    )
    const hero = this.props.data.contentfulPage.sections[0]
    const imageGrid = this.props.data.contentfulPage.sections[1]
    return (
      <Layout>
        <SEO customTitle="Case Studies" pagePath="case-studies" />
        <Hero pageTitle="Case Studies" {...hero} />
        <div>{caseStudiesArray}</div>
        <LogoGrid {...imageGrid} />
        <Footer isFullHeight={false} />
      </Layout>
    )
  }
}

export default CaseStudiesPage

export const pageQuery = graphql`
  query {
    contentfulPage(title: { eq: "Case Studies" }) {
      sections {
        ... on ContentfulHero {
          header
          headerHighlightedText
          headerSize
          subheader
        }
        ... on ContentfulImageGrid {
          sectionTitle
          logos {
            image {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            link
          }
        }
      }
      caseStudies {
        title
        slug
        keywords
        isOnHomePage
        backgroundColor
        thumbnailImage {
          fluid(maxWidth: 474, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        sketch {
          mobileImage: fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          desktopImage: fluid(maxWidth: 375) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        whiteSketch {
          mobileImage: fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          desktopImage: fluid(maxWidth: 375) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        projectDescription {
          projectDescription
        }
      }
    }
  }
`
